html {
    scroll-behavior: smooth;
}

.input ::placeholder {
    color: black;
    font-weight: 500;
    font-size: 13px;
    opacity: 1;
}

/* navbar */

.navbar-brand {
    padding-top: 0 !important;
}

.navbar-nav .nav-item.active .nav-link {
    color: #1a3dbc !important;
    border-bottom: 2px solid #1a3dbc;
}

.navbar {
    background-color: white;
    padding-top: 12px;
    padding-bottom: 15px;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 12px;
}

.navbar-toggler-icon {
    width: 1rem;
    height: 1.2em;
}

.navbar-nav li a {
    transition: 1s ease;
}

.navbar-nav li a:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
    color: #1a3dbc !important;
}

.nav-link {
    color: black;
    font-style: italic;
    font-weight: bold;
    font-size: 1.1rem;
}

.logo img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.logo {
    height: 51px;
    width: 185px;
}

button.navbar-toggler {
    background-color: #000080 !important;
}

/* first conatiner */
.heading {
    font-size: 2.2rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 25px;

}

.h3content {
    margin-bottom: 25px;
}

.img {
    background: url("../src//images//truck1.png") no-repeat center/cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-caption {
    bottom: 13.25rem !important;
}

.play img {
    height: 100%;
    object-fit: contain;
}

.play {
    height: 85px;
    cursor: pointer;
}

/* second container */
p.spaceing p{
    margin-top: 0;
    margin-bottom: 0;
}

@media screen and (max-width: 425px) {
    .paddingMision {
        padding: 25px 0 !important;
    }

    p.p.content_why {
        padding: 0 20px;
    }

    .logo {
        height: 31px;
        width: 119px;
    }

    .cardshadow.padding_why.marginservices {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .padding_why {
        padding: 17px !important;
    }
}

.paddingMision {
    padding: 50px 60px 60px 60px;
}

.About {
    background-color: #EFEFF7;
}

.missionImg img {
    height: 100%;
    width: 100%;
}

.contact {
    position: relative;
    cursor: pointer;
}

.conatct_text {
    position: absolute;
    color: #fff;
    top: 11px;
}

.about_truck img {
    height: 100%;
    width: 100%;
    object-fit: contain;

}

.about_truck {
    margin-bottom: 42px;
}

.parentMission {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
}

.content {
    padding: 60px;
    position: absolute;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}

/* third */ 
.padding_why.about{
    padding: 50px 0 !important;
}
.card.cardstyle.p-3 {
    border-radius: 0;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px;
}

.card-body {
    padding: 0;
    margin-top: 30px;
}

p.card-text {
    font-weight: 500;
    font-size: 16px;
    color: #1E1E1E;
    font-style: italic;
    text-align: center;
}

.card-img,
.card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.padding_why {
    padding: 50px;
}
.marginMission {
    padding: 0px 50px 50px 50px;
}

.heading_why {
    font-size: 2.5rem;
    font-weight: bold;
    color: #000;
}

.heading_why span {
    color: #000080;
}

.content_why {
    line-height: 1.8;
    font-weight: 500;
    font-size: 13px;
    color: #1E1E1E;
}

.images img {
    height: 100%;
    width: 100%;
}

.truck {
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}

.dowmload_button img {
    height: 100%;
    width: 100%;
}

.dowmload_button {
    height: 48px;
    width: 226px;
}

.dowmload {
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.pading {
    padding: 0 30px 10px 20px
}

.download_text {
    position: absolute;
    color: #fff;
    top: 11px;
}

/* services */
.row.services {
    padding-right: 0;
}
.cardshadow {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
}

.l_img img {
    height: 100%;
    object-fit: contain;
}

.l_img {
    height: 30px;
    min-width: 25px;
}

.r_img {
    height: 25px;
    min-width: 25px;
}

.r_img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

/* fourth */
.download_h6 {
    font-weight: bold;
    line-height: 1.8;
    font-size: 1.2rem;
}

.image_banner img {
    height: 100%;
    width: 100%;
}

.paddind_contact {
    padding: 0 40px 0 70px;
}

.paddind_contact_heading {
    padding: 50px 40px 0 70px;
}

/* footer */

.footer a.nav-link {
    transition: 1s ease;
}

.footer a.nav-link:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
    color: #000080;
}

.footerimg img {
    height: 100%;
    width: 100%;
}

.footerimg {
    width: 350px;
    margin: 0 auto;
    padding: 40px;
}

.footer {
    font-weight: bold;
    font-size: 1.1rem;
}

.imagefooter img {
    height: 100%;
    width: 100%;
}

.imagefooter {
    height: 70px;
}

.parent_footer {
    position: relative;
}

.footer_text {
      top: 25px;
}

.footer_text {
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
    background-color: #000080;
}

.solidappmaker img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.solidappmaker {
    height: 35px;
    width: 84px;
    cursor: pointer;
}

.nav_bottom {
    padding: 0 40px 40px 40px;
    text-align: center;
}

.form_bottom {
    padding-bottom: 40px;
}

.contactspace {
    padding-left: 0;
}

.heading_why span {
    white-space: nowrap;
}

/* buttons */

.play img {
    transition: 1s ease;
}

.play img:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
}

@media (max-width: 768px) {
    .heading_why span {
        display: block;
        text-align: center;
    }
    .heading_why.contact  span {
        display: contents;
        text-align: center;
    }

    .cardstyle {
        top: 50px;
        transform: rotate(0deg);
    }

    h6.about.heading_why {
        text-align: center;
    }

    h6.heading_why.textCenter {
        text-align: center;
    }

    .parent_footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .solidappmaker {
        height: 45px;
        width: 232px;
    }

    .padding_why.services {
        padding: 50px 25px;
    }

    .imagefooter {
        height: 128px;
    }

    .solidappmaker {
        display: block;
        margin: 10px auto;
    }
}

@media (max-width: 1990px) and (min-width :1500px) {
    .carousel-caption {
        bottom: 27.25rem !important;
    }
}

@media (max-width: 868px) and (min-width :576px) {
    .carousel-caption {
        bottom: 5.6rem !important;
    }
}

@media screen and (max-width: 425px) {
    .col-md-6.amenties {
        padding-right: 0;
        padding-left: 0;
    }

    .marginMission {
        padding: 0px 25px 20px 30px;
    }
    .carousel-caption {
        padding-top: 0 !important;
    }

    .paddind_contact_heading {
        padding: 40px 40px 0 40px;
    }

    .heading_why {
        font-size: 2rem;
    }

    .paddind_contact {
        /* padding: 0 40px 0 40px; */
        padding: 0;
    }

    .imagefooter {
        height: 60px;
    }

    .truck {
        font-size: 17px;
    }

    .solidappmaker {
        height: 25px;
    }

    .footer_text {
        font-size: 10px;
        top: 16%;
    }

    .play {
        height: 55px;
    }

    .carousel-caption {
        bottom: 36px !important;
    }

    .heading {
        font-size: 23px;
    }

    .h3content {
        font-size: 15px;
    }

    .heading {
        margin-bottom: 10px;
    }

    .h3content {
        margin-bottom: 10px;
    }

    .contact {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 404px) {
    .carousel-caption {
        bottom: 7px !important;
    }

    .heading_why {
        font-size: 2rem;
    }

    .heading {
        font-size: 20px;
    }

    .h3content {
        font-size: 12px;
    }

    .h3content {
        margin-bottom: 7px;
    }

    .footerimg {
        width: 300px;
    }
}

@media screen and (max-width: 320px) {
    .heading {
        font-size: 17px;
    }

    .h3content {
        font-size: 11px;
    }
}

@media (max-width: 2000px) and (min-width :768px) {
    .conatct_text {
        left: 92px !important;
    }
}

.logo {
    position: relative;
}

.logo img {
    display: block;
    width: 100%;
    height: auto;
    animation: move 1s ease-in-out infinite alternate;
}

@keyframes move {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(10px);
    }
}

.videoshow {
    height: 100%;
    width: 100%;
}

.videocontainer {
    position: relative;
    height: 87vh;
}

.videoshow {
    display: block;
    object-fit: cover;
    width: 100%;
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
}

.videoshow {
    /* filter: brightness(50%); */
}

.navbar-nav li {
    opacity: 0;
    animation: fadeIn 1.5s ease-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.heading_why {
    opacity: 0;
    animation: fadeIn 3.5s ease-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.videoshowMission {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cardstyle {
    position: relative;
    border: solid 12px #fff;
    background: #fff;
    box-shadow: 0 0 15px 0px #555;
    transition: all 1s ease;
    -o-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    top: 50px;
}
.contactbutton{
    cursor: pointer;
}

.cardstyle:hover {
    top: 0px;
}

.cardstyle:nth-child(1) {
    transform: rotate(0deg);
}

.cardstyle:nth-child(2) {
    transform: rotate(0deg);
}

.cardstyle:nth-child(3) {
    transform: rotate(0deg);
}

.cardstyle:nth-child(4) {
    transform: rotate(0deg);
}

@media (max-width: 767px) {
    .contactbutton{
        margin: 0 auto;
    }
    .cardstyle {
        top: 20px;
        transform: none;
    }

    .card.cardstyle.p-3 {
        margin-bottom: 30px;
    }
}

@media (max-width: 1980px) and (min-width :1500px) {
    .content {
        font-size: 19px;
    }
}

@media (max-width: 926px) and (min-width :768px) {
    .content {
        font-size: 12px;
    }
}

@media (max-width: 767px) and (min-width :600px) {
    .content {
        font-size: 10px;
    }
}

@media (max-width: 599px) and (min-width :400px) {
    .content {
        font-size: 6px;
    }
}

@media (max-width: 399px) {
    .content {
        font-size: 4px;
    }
}

@media screen and (max-width: 1024px) {
    .videocontainer {
        height: auto !important;
    }
}

@media screen and (max-width: 768px) {
    .paddingMision {
        padding: 35px 0px 60px 31px;
    }
    .footer a.nav-link{
        font-size: 0.8rem;
    }
    .footerimg{
width: 300px;
    }
    
}

@media (max-width: 1980px) and (min-width :1550px) {

    .content_why {
        font-size: 20px !important;
    }

    .paddingMision {
        padding: 50px 150px 60px 120px;
    }

    .l_img {
        height: 40px;
        min-width: 40px;
    }

    .heading {
        font-size: 3.2rem !important;
    }

    .heading_why {
        font-size: 2.7rem;
    }

    p.card-text {
        font-size: 20px;
    }

    .input ::placeholder {
        font-size: 17px;
        /* Firefox */
    }
}

@media (max-width: 1550px) and (min-width :1440px) {

    .content_why {
        font-size: 17px !important;
    }

    .content {
        font-size: 16px !important;
    }

    .l_img {
        height: 42px;
        min-width: 39px;
    }

    p.card-text {
        font-size: 20px;
    }
}
@media (max-width: 992px) and (min-width :768px){
    .nav-link{
        font-size: 0.8rem;
    }
    .navbar-expand-md .navbar-nav .nav-link{
        padding-right: 0;
        padding-left: 0;
    }
   
}
.row.marginMission {
    margin-right: 0;
}
.contactbutton{
    background: linear-gradient(to right, #000080, #0E4C92);
    color: white;
    height: 41px;
    max-width: 237px;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    align-items: center;
}
.col-md-6.amenties {
    padding-right: 0;
    padding-left: 0;
}

.row.padding_why.paddingless {
    padding: 50px 30px;
}
.cardshadow.padding_why.marginservices {
    padding: 50px 30px;
}
@media (min-width: 768px) {
    .row.padding_why.paddingless {
        padding: 50px 50px;
    }
}

.boxshadowNav {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.fixedheight{
    min-height: 196px;
}
.card-img-top {
    width: 100%;
    min-height: 300px;
    max-height: 300px;
}